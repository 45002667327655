<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useGameContentStore } from '~/store/GameContent'
  import useGameCity from '~/composables/GameCity/GameCity'
  import useGameDistrict from '~/composables/GameDistrict/GameDistrict'
  import useGameLocation from '~/composables/GameLocation/GameLocation'
  import useGameInteraction from '~/composables/GameInteraction/GameInteraction'
  import useGameHierarchy from '~/composables/GameHierarchy/GameHierarchy'
  import UiIcon from '~/components/Ui/UiIcon/UiIcon.vue'
  import { GameHierarchyId } from '~/model/GameHierarchy/GameHierarchy'
  import { UiIconId, UiIconNameId, UiIconSeriesId, UiIconSizeId } from '~/model/UiIcon/UiIcon'
  import useUiIcon from '~/composables/UiIcon/UiIcon'
  import { useGameBreadcrumbStore } from '~/store/GameBreadcrumb'

  const gameContentStore = useGameContentStore()
  const gameBreadcrumbStore = useGameBreadcrumbStore()

  const { cityContent, districtContent, locationContent, interactionContent } =
    storeToRefs(gameContentStore)
  const { breadcrumb } = storeToRefs(gameBreadcrumbStore)

  const { findIcon } = useUiIcon()
  const { toGameCity } = useGameCity()
  const { toGameDistrict } = useGameDistrict()
  const { toGameLocation } = useGameLocation()
  const { toGameInteraction } = useGameInteraction()
  const { gameHierarchyDict } = useGameHierarchy()

  const getBaseIconId = (name: UiIconNameId): UiIconId | undefined => {
    return findIcon({ name, series: UiIconSeriesId.CoreWhiteNeg })
  }

  const getHoverIconId = (name: UiIconNameId): UiIconId | undefined => {
    return findIcon({ name, series: UiIconSeriesId.CoreCyanNeg })
  }
</script>

<template>
  <div class="c-navigation-breadcrumb u-typography-root">
    <ClientOnly>
      <ol
        class="c-navigation-breadcrumb__list u-reset"
        itemscope
        itemtype="https://schema.org/BreadcrumbList"
      >
        <li
          v-if="breadcrumb.cityId && cityContent"
          class="c-navigation-breadcrumb__entry"
          itemprop="itemListElement"
          itemscope
          itemtype="https://schema.org/ListItem"
        >
          <NuxtLink
            :to="toGameCity(breadcrumb.cityId)"
            class="s-header-entry__wrap c-navigation-breadcrumb__link u-reset"
            itemprop="item"
          >
            <span class="s-header-entry__icon-box c-navigation-breadcrumb__icon-box">
              <UiIcon
                :id="getBaseIconId(gameHierarchyDict[GameHierarchyId.City].icon)"
                :display-size="UiIconSizeId.Large"
                class="s-header-entry__icon c-navigation-breadcrumb__icon c-navigation-breadcrumb__icon--base"
              />
              <UiIcon
                :id="getHoverIconId(gameHierarchyDict[GameHierarchyId.City].icon)"
                :display-size="UiIconSizeId.Large"
                class="s-header-entry__icon c-navigation-breadcrumb__icon c-navigation-breadcrumb__icon--hover"
              />
            </span>
            <span
              class="s-header-entry__description c-navigation-breadcrumb__description"
              itemprop="name"
            >
              <span class="c-navigation-breadcrumb__description-text">
                {{ cityContent.name }}
              </span>
            </span>
            <meta content="1" itemprop="position" />
          </NuxtLink>
        </li>
        <li
          v-if="breadcrumb.districtId && districtContent"
          class="c-navigation-breadcrumb__entry"
          itemprop="itemListElement"
          itemscope
          itemtype="https://schema.org/ListItem"
        >
          <NuxtLink
            :to="toGameDistrict(breadcrumb.districtId)"
            class="s-header-entry__wrap c-navigation-breadcrumb__link u-reset"
            itemprop="item"
          >
            <span class="s-header-entry__icon-box c-navigation-breadcrumb__icon-box">
              <UiIcon
                :id="getBaseIconId(gameHierarchyDict[GameHierarchyId.District].icon)"
                :display-size="UiIconSizeId.Large"
                class="s-header-entry__icon c-navigation-breadcrumb__icon c-navigation-breadcrumb__icon--base"
              />
              <UiIcon
                :id="getHoverIconId(gameHierarchyDict[GameHierarchyId.District].icon)"
                :display-size="UiIconSizeId.Large"
                class="s-header-entry__icon c-navigation-breadcrumb__icon c-navigation-breadcrumb__icon--hover"
              />
            </span>
            <span
              class="s-header-entry__description c-navigation-breadcrumb__description"
              itemprop="name"
            >
              <span class="c-navigation-breadcrumb__description-text">
                {{ districtContent.name }}
              </span>
            </span>
            <meta content="2" itemprop="position" />
          </NuxtLink>
        </li>
        <li
          v-if="breadcrumb.locationId && locationContent"
          class="c-navigation-breadcrumb__entry"
          itemprop="itemListElement"
          itemscope
          itemtype="https://schema.org/ListItem"
        >
          <NuxtLink
            :to="toGameLocation(breadcrumb.locationId)"
            class="s-header-entry__wrap c-navigation-breadcrumb__link u-reset"
            itemprop="item"
          >
            <span class="s-header-entry__icon-box c-navigation-breadcrumb__icon-box">
              <UiIcon
                :id="getBaseIconId(gameHierarchyDict[GameHierarchyId.Location].icon)"
                :display-size="UiIconSizeId.Large"
                class="s-header-entry__icon c-navigation-breadcrumb__icon c-navigation-breadcrumb__icon--base"
              />
              <UiIcon
                :id="getHoverIconId(gameHierarchyDict[GameHierarchyId.Location].icon)"
                :display-size="UiIconSizeId.Large"
                class="s-header-entry__icon c-navigation-breadcrumb__icon c-navigation-breadcrumb__icon--hover"
              />
            </span>
            <span
              class="s-header-entry__description c-navigation-breadcrumb__description"
              itemprop="name"
            >
              <span class="c-navigation-breadcrumb__description-text">
                {{ locationContent.name }}
              </span>
            </span>
            <meta content="3" itemprop="position" />
          </NuxtLink>
        </li>
        <li
          v-if="breadcrumb.interactionId && interactionContent"
          class="c-navigation-breadcrumb__entry"
          itemprop="itemListElement"
          itemscope
          itemtype="https://schema.org/ListItem"
        >
          <NuxtLink
            :to="toGameInteraction(breadcrumb.interactionId)"
            class="s-header-entry__wrap c-navigation-breadcrumb__link u-reset"
            itemprop="item"
          >
            <span class="s-header-entry__icon-box c-navigation-breadcrumb__icon-box">
              <UiIcon
                :id="getBaseIconId(gameHierarchyDict[GameHierarchyId.Interaction].icon)"
                :display-size="UiIconSizeId.Large"
                class="s-header-entry__icon c-navigation-breadcrumb__icon c-navigation-breadcrumb__icon--base"
              />
              <UiIcon
                :id="getHoverIconId(gameHierarchyDict[GameHierarchyId.Interaction].icon)"
                :display-size="UiIconSizeId.Large"
                class="s-header-entry__icon c-navigation-breadcrumb__icon c-navigation-breadcrumb__icon--hover"
              />
            </span>
            <span
              class="s-header-entry__description c-navigation-breadcrumb__description"
              itemprop="name"
            >
              <span class="c-navigation-breadcrumb__description-text">
                {{ interactionContent.name }}
              </span>
            </span>
            <meta content="4" itemprop="position" />
          </NuxtLink>
        </li>
      </ol>
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
  @use 'sass:map';
  @use '@nirazul/scss-utils' as utils;
  @use '/assets/scss/base/typography/typography' as type;
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/util/transition' as trs;
  @use '/assets/scss/util/text-flag' as text-flag;

  .c-navigation-breadcrumb {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 24px;
  }

  .c-navigation-breadcrumb__list {
    display: flex;
    flex-flow: row nowrap;
    gap: 24px;
  }

  .c-navigation-breadcrumb__link {
    @include trs.common-props;
    color: col.$variant-cyan-light;
  }

  .c-navigation-breadcrumb__entry:not(:last-of-type) {
    .c-navigation-breadcrumb__description {
      @include text-flag.style;
      @include text-flag.base;
      padding: 0;
    }

    .c-navigation-breadcrumb__description-text {
      padding: 4px 8px;
      background-color: col.$monochrome-black;
    }

    .c-navigation-breadcrumb__icon {
      &.c-navigation-breadcrumb__icon--hover {
        @include utils.is-visible(false, trs.$default-speed);
      }
    }

    .c-navigation-breadcrumb__link {
      @include utils.has-focus {
        .c-navigation-breadcrumb__description {
          @include text-flag.hover;
        }

        .c-navigation-breadcrumb__icon {
          &.c-navigation-breadcrumb__icon--hover {
            @include utils.is-visible(true, trs.$default-speed);
          }
        }
      }
    }
  }

  .c-navigation-breadcrumb__entry:last-of-type {
    .c-navigation-breadcrumb__link:not(.u-router-link-exact-active) {
      @include utils.has-focus() {
        color: col.$brand-cyan;
      }
    }

    .c-navigation-breadcrumb__description {
      position: relative;
    }
  }
</style>
